<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            template(v-if="status_detail.status === 'loading'")
                h2.text-xl -
            template(v-else-if="detail.msisdn && detail.msisdn.length > 0")
                h2.text-xl {{ detail.msisdn ? detail.msisdn : '-' }}
            template(v-else)
                h2.text-xl -            
            .header-cta    
                router-link(to="/prediction-msisdn").btn-default
                    IconSet(type="chevron-left")
                    span.m-hide.ml-3 Back

        .card--table__body
            .box-table.box-table--target
                table.table
                    thead.m-hide
                        tr
                            th(
                                v-for="(th, index) in tableThead",
                                :key="index",
                                :class="(th.number && th.number === true ? 'number' : '') + (th.action && th.action === true ? ' thead-action' : '')"
                            ) {{ th.name }}
                    tbody
                        tr(v-if="status_detail.status === 'loading'")
                            td.text-center(:colspan="tableThead.length")
                                .flex.flex-wrap.items-center.justify-center
                                    Spinner(show="true" size="48")

                        template(v-else-if="detail.data && detail.data.length > 0")
                            tr(v-for="(data,index) in detail.data" :key="data.id")
                                td {{ data.msisdn ? data.msisdn:'-' }}
                                td {{ data.tanggal_registrasi ? data.tanggal_registrasi:'-' }}
                                td {{ data.operator ? data.operator:'-' }}
                                td {{ data.status ? data.status:'-' }}
                                td {{ data.nik ? data.nik:'-' }}
                                td {{ data.request_at ? data.request_at.substring(0, 19).replace('T',' ') : '-' }}

                        tr(v-else)
                            td.text-center(:colspan="tableThead.length") No Data Available


     
</template>

<script>
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";
import { mapState } from "vuex";
import Spinner from "@/components/Spinner.vue";

export default {
    name: 'prediction-msisdn-detail',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
    },

    data() {
        return {
            tableThead: [
                {
                name: "MSISDN",
                },
                {
                name: "Registration Date",
                },                
                {
                name: "Provider Name",
                },
                {
                name: "Status",
                },  
                {
                name: "NIK",
                },                              
                {
                name: "Request At",
                },                
            ],
            total_data: 0,
            items_per_page: 10,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,

            keyword: "",
            timeout: null,

            openPopUp: false,
            popUpWatchlist: false,
            dateRange: {
                startDate: '2022-01-01',
                endDate: '2022-01-31',
            },

            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

        }
    },
    computed:{
        ...mapState("predictionMSISDN", [
            "status_detail",
            "detail",
        ]),
        route_name() {
            return this.$route.name;
        },    
        id() {
            return this.$route.params.id;
        },
    },    
    watch: {
        route_name(newData, oldData) {
            if (newData === 'prediction-msisdn-detail') {
                if (oldData !== 'prediction-msisdn-detail') {
                    this.$store.commit('predictionMSISDN/setDetail', {});
                }

                this.getData();
            }
        },
        detail(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);
            } else {
                this.total_data = 0;
            }
        },        
        id() {
            this.getData();
        },
        items_per_page() {
            this.getData();
        },    
    },
    methods: {
        getData() {
            if (this.route_name === 'prediction-msisdn-detail') {
                EventEmit.$emit('showLoading', true);

                this.$store.dispatch('predictionMSISDN/getDetail', this.id)
                .then((resp) => {
                    if (resp.result === 'success') {
                        EventEmit.$emit('showLoading', false);
                    } else {
                        EventEmit.$emit('error');
                    }
                });
            }
        },         
    },

    created() {
        this.getData();
    },
    mounted() {
        
    },

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
}
</script>


<style lang="sass">
.btn-close-search
    @apply absolute top-0 right-0 flex items-center justify-center
    width: 46px
    height: 46px

.box-search-result
    background-color: $color-secondary-600
    border: 1px solid lighten($color-secondary-500, 10%)
    border-top: 0
    ul
        max-height: 180px
        overflow: auto
    button
        @apply block py-3 px-5
        &:hover
            color: $color-main-500

</style>